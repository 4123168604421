<template>
    <section id="dashboard-ecommerce">
        <b-row class="match-height">
            <b-col>
                <div class="card round p-2">
                    <h3>Felicitaciones Oscar🎉</h3>
                    <h4 class="text-black-50">
                        ¡Tuviste un gran rendimiento esta semana!
                    </h4>
                    <div class="text-right position-absolute position-right-0">
                        <img
                            src="/img/general/medal_1.png"
                            alt="Medalla"
                            height="100"
                            width="107"
                            class="animate__animated animate__swing"
                        />
                        <img
                            src="/img/general/medal_2.png"
                            alt="Medalla"
                            height="100"
                            width="107"
                            class="animate__animated animate__swing"
                        />
                        <img
                            src="/img/general/medal_3.png"
                            alt="Medalla"
                            height="100"
                            width="107"
                            class="animate__animated animate__swing"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col>
                <b-card
                    class="round mx-2 animate__animated animate__pulse animate__slower animate__infinite infinite"
                >
                    <b-row>
                        <b-col cols="auto">
                            <b-avatar
                                variant="info"
                                src="/img/omron-logo-og-1200px.png"
                            >
                            </b-avatar>
                        </b-col>
                        <b-col>
                            <b-row>
                                <span>Gian Carlos Galán</span>
                            </b-row>
                            <b-row>
                                <small class="text-success"
                                    >Tienes cita a las 12:30pm</small
                                >
                            </b-row>
                        </b-col>
                        <b-col cols="2">
                            <b-button pill variant="primary">
                                Ir a la cita
                            </b-button>
                        </b-col>
                        <b-col cols="auto">
                            <feather-icon
                                icon="XIcon"
                                class="float-right"
                            ></feather-icon>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col>
                <b-card class="round mx-2">
                    <b-row>
                        <b-col cols="auto">
                            <b-avatar
                                variant="info"
                                src="/img/omron-logo-og-1200px.png"
                            >
                            </b-avatar>
                        </b-col>
                        <b-col>
                            <b-row>
                                <span>Gian Carlos Galán</span>
                            </b-row>
                            <b-row>
                                <small class="text-danger"
                                    >Incumpliste una cita a las 12:00m</small
                                >
                            </b-row>
                        </b-col>
                        <b-col cols="2">
                            <b-button pill variant="danger">
                                Reagendar
                            </b-button>
                        </b-col>
                        <b-col cols="auto">
                            <feather-icon
                                icon="XIcon"
                                class="float-right"
                            ></feather-icon>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col>
                <div class="card round">
                    <div class="flex-column text-center">
                        <feather-icon
                            icon="BellIcon"
                            size="40"
                            class="mt-1 text-warning sombra-indicadores"
                        ></feather-icon>
                        <p class="mb-25 mt-50 font-weight-bolder font-medium-4">
                            3
                        </p>
                        <p>Actividades pendientes para hoy</p>
                    </div>
                </div>
            </b-col>
            <b-col>
                <div class="card round">
                    <div class="flex-column text-center">
                        <feather-icon
                            icon="ActivityIcon"
                            size="40"
                            class="mt-1 text-primary sombra-indicadores"
                        ></feather-icon>
                        <p class="mb-25 mt-50 font-weight-bolder font-medium-4">
                            5
                        </p>
                        <p>Asistencias por realizar hoy</p>
                    </div>
                </div>
            </b-col>
            <b-col>
                <div class="card round">
                    <div class="flex-column text-center">
                        <feather-icon
                            icon="ActivityIcon"
                            size="40"
                            class="mt-1 text-danger sombra-indicadores"
                        ></feather-icon>
                        <p class="mb-25 mt-50 font-weight-bolder font-medium-4">
                            300
                        </p>
                        <p>Asistencias canceladas hoy</p>
                    </div>
                </div>
            </b-col>
            <b-col>
                <div class="card round">
                    <div class="flex-column text-center">
                        <feather-icon
                            icon="StarIcon"
                            size="40"
                            class="mt-1 text-yellow sombra-indicadores"
                        ></feather-icon>
                        <p class="mb-25 mt-50 font-weight-bolder font-medium-4">
                            4.9
                        </p>
                        <p>Mi puntuación semanal</p>
                    </div>
                </div>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import Login from "@/services/auth";
import { ref, reactive, onMounted } from "@vue/composition-api";

export default {
    setup() {
        async function getApi() {
            try {
                const { data } = await Login.getUser();
                console.log(data);
            } catch (error) {}
        }
        onMounted(() => {
            getApi();
        });

        return {};
    },
};
</script>

<style lang="scss">
.text-yellow {
    color: gold;
}
.sombra-indicadores {
    filter: drop-shadow(1px 2px 5px);
}
</style>
